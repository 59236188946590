import React from 'react';

import { GroupTable } from './GroupTable';

/** Группы контрагентов */
export const ContractorGroups = () => {
  return (
    <div>
      <GroupTable />
    </div>
  );
};

export const INIT = '[FOLDER] INIT';
export const CLEAR = '[FOLDER] CLEAR';

export const REQUEST = '[FOLDER] LIST REQUEST';
export const SUCCESS = '[FOLDER] LIST SUCCESS';
export const FAILURE = '[FOLDER] LIST FAILURE';

export const DIALOG = '[FOLDER] SET DIALOG';
export const SELECTED = '[FOLDER] SET SELECTED';

export const REMOVE = '[FOLDER] SET REMOVE';
export const REMOVE_REQUEST = '[FOLDER] REMOVE REQUEST';
export const REMOVE_SUCCESS = '[FOLDER] REMOVE SUCCESS';
export const REMOVE_FAILURE = '[FOLDER] REMOVE FAILURE';

export const ADD_PACKAGE = '[FOLDER] ATTACH PACKAGE';
export const ADD_PACKAGE_REQUEST = '[FOLDER] ATTACH PACKAGE REQUEST';
export const ADD_PACKAGE_SUCCESS = '[FOLDER] ATTACH PACKAGE SUCCESS';
export const ADD_PACKAGE_FAILURE = '[FOLDER] ATTACH PACKAGE FAILURE';

export const DETACH_PACKAGE = '[FOLDER] DETACH PACKAGE';
export const DETACH_PACKAGE_REQUEST = '[FOLDER] DETACH PACKAGE REQUEST';
export const DETACH_PACKAGE_SUCCESS = '[FOLDER] DETACH PACKAGE SUCCESS';
export const DETACH_PACKAGE_FAILURE = '[FOLDER] DETACH PACKAGE FAILURE';

export const FORM_SUBMIT = '[FOLDER] FORM SUBMIT';
export const FORM_REQUEST = '[FOLDER] FORM REQUEST';
export const FORM_SUCCESS = '[FOLDER] FORM SUCCESS';
export const FORM_FAILURE = '[FOLDER] FORM FAILURE';

export const DOCUMENT_INIT = '[DOCUMENTS] INIT';
export const DOCUMENT_MODE = '[DOCUMENTS] SET MODE';
export const DOCUMENT_MODAL_SEARCH = '[DOCUMENTS] MODAL SEARCH';

export const DOCUMENT_TOOLS = '[DOCUMENTS] TOOLS';
export const DOCUMENT_SET_TOOLS = '[DOCUMENTS] SET TOOLS';

export const DOCUMENT_SET_SELECTED = '[DOCUMENTS] SET SELECTED';
export const DOCUMENT_CHECKED = '[DOCUMENTS] CHECKED';

export const DOCUMENT_DOWNLOAD_REQUEST = '[DOCUMENTS] DOWNLOAD REQUEST';
export const DOCUMENT_DOWNLOAD_SUCCESS = '[DOCUMENTS] DOWNLOAD SUCCESS';
export const DOCUMENT_DOWNLOAD_FAILURE = '[DOCUMENTS] DOWNLOAD FAILURE';

export const DOCUMENT_DELETE = '[DOCUMENTS] DELETE';
export const DOCUMENT_DELETE_REQUEST = '[DOCUMENTS] DELETE REQUEST';
export const DOCUMENT_DELETE_SUCCESS = '[DOCUMENTS] DELETE SUCCESS';
export const DOCUMENT_DELETE_FAILURE = '[DOCUMENTS] DELETE FAILURE';

export const DOCUMENT_ARCHIVE = '[DOCUMENTS] ARCHIVE';
export const DOCUMENT_ARCHIVE_REQUEST = '[DOCUMENTS] ARCHIVE REQUEST';
export const DOCUMENT_ARCHIVE_SUCCESS = '[DOCUMENTS] ARCHIVE SUCCESS';
export const DOCUMENT_ARCHIVE_FAILURE = '[DOCUMENTS] ARCHIVE FAILURE';

export const DOCUMENT_SIGN = '[DOCUMENTS] SIGN';
export const DOCUMENT_SIGN_REQUEST = '[DOCUMENTS] SIGN REQUEST';
export const DOCUMENT_SIGN_SUCCESS = '[DOCUMENTS] SIGN SUCCESS';
export const DOCUMENT_SIGN_FAILURE = '[DOCUMENTS] SIGN FAILURE';

export const DOCUMENT_SIGN_CONTAINER = '[DOCUMENTS] SIGN CONTAINER';
export const DOCUMENT_SIGN_CONTAINER_REQUEST = '[DOCUMENTS] SIGN CONTAINER REQUEST';
export const DOCUMENT_SIGN_CONTAINER_SUCCESS = '[DOCUMENTS] SIGN CONTAINER SUCCESS';
export const DOCUMENT_SIGN_CONTAINER_FAILURE = '[DOCUMENTS] SIGN CONTAINER FAILURE';

export const DOCUMENT_NEXT = '[DOCUMENTS] NEXT';
export const DOCUMENT_NEXT_REQUEST = '[DOCUMENTS] NEXT REQUEST';
export const DOCUMENT_NEXT_SECCESS = '[DOCUMENTS] NEXT SECCESS';
export const DOCUMENT_NEXT_FAILURE = '[DOCUMENTS] NEXT FAILURE';
export const DOCUMENT_REQUEST = '[DOCUMENTS] REQUEST';
export const DOCUMENT_SUCCESS = '[DOCUMENTS] SUCCESS';
export const DOCUMENT_FAILURE = '[DOCUMENTS] FAILURE';
export const DOCUMENT_RELOAD = '[DOCUMENTS] RELOAD';
export const DOCUMENT_UPDATE = '[DOCUMENTS] UPDATE';
export const DOCUMENT_FILTER = '[DOCUMENTS] SET FILTER';

export const DOCUMENT_DETATCH_FOLDER = '[DOCUMENTS] DETATCH FOLDER';
export const DOCUMENT_ATTATCH_FOLDER = '[DOCUMENTS] ATTATCH FOLDER';

export const INIT = '[SIGN] INIT';
export const CLEAR = '[SIGN] CLEAR';

export const AUTOSIGNING = '[SIGN] AUTOSIGNING';

export const CHECK_LIST_REQUEST = '[SIGN] CHECK LIST REQUEST';
export const CHECK_LIST_SUCCESS = '[SIGN] CHECK LIST SUCCESS';
export const CHECK_LIST_FAILURE = '[SIGN] CHECK LIST FAILURE';

export const STOP_PROCESS = '[SIGN] STOP PROCESS';
export const START_PROCESS = '[SIGN] START PROCESS';

export const SIGN_TOTAL = '[SIGN] SET TOTAL';
export const SIGN_CURRENT = '[SIGN] SET CURRENT';

export const SET_HAS_INCOMPLETE_ROLE = '[SIGN] SET HAS INCOMPLETE ROLE';

export const TOP = 'top';
export const TOP_LEFT = 'topLeft';
export const TOP_RIGHT = 'topRight';
export const BOTTOM = 'bottom';
export const BOTTOM_LEFT = 'bottomLeft';
export const BOTTOM_RIGHT = 'bottomRight';
export const LEFT = 'left';
export const LEFT_TOP = 'leftTop';
export const LEFT_BOTTOM = 'leftBottom';
export const RIGHT = 'right';
export const RIGHT_TOP = 'rightTop';
export const RIGHT_BOTTOM = 'rightBottom';
export const CENTER = 'center';
